import { Fragment } from "react";
import dynamic from "next/dynamic";
import { SiteContext } from "../Contexts/SiteContext";

const ProductHeader = dynamic(import("../Header/ProductHeader"));
const GeneralFooter = dynamic(import("../Footer/GeneralFooter"));

export default function ProductLayout(props) {
	return (
		<SiteContext.Consumer>
			{() => (
				<Fragment>
					<div 
						className="layout"
					>
						<ProductHeader
							nav={props.nav}
							logo={props.logo}
						/>
						{props.children}			
						{props.footer !== false && (
							<GeneralFooter footer={props.footer} />
						)}
					</div>
				</Fragment>
			)}
		</SiteContext.Consumer>
	);
}